<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="学员信息">
              <a-input
                placeholder="请输入手机号/姓名/身份证号"
                v-model="where.keyword.studentName"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="证书名称">
              <a-input
                placeholder="请输入"
                v-model="where.keyword.certificateName"
              />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-button
              type="primary"
              @click="searchList"
              style="margin-top: 5px"
            >
              搜索
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div>
      <a-table
        rowKey="id"
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onChange"
        :dataSource="list.datas"
        :loading="isloading"
        bordered
        size="small"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <template slot="status" slot-scope="status">
          <span v-if="status == 1">生成中</span>
          <span v-if="status == 2" style="color: #52c41a">完成</span>
          <span v-if="status == 3" style="color: #ff4d4f">生成失败</span>
        </template>
        <span slot="action" slot-scope="info">
          <a-button
            v-if="info.status == 3"
            type="primary"
            size="small"
            @click="createCert(info.id, info.middleId)"
          >
            生成证书
          </a-button>
          <a-button
            v-if="info.status == 2"
            type="primary"
            size="small"
            @click="showCert(info.downloadUrl)"
          >
            查看
          </a-button>
          <a-popconfirm
            title="是否确认删除此证书记录"
            @confirm="delCertRecord(info.id)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <a-button type="danger" size="small"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
var that;
const columns = [
  {
    dataIndex: "studentName",
    align: "center",
    title: "学员姓名",
    width: 120,
  },
  {
    dataIndex: "telephone",
    align: "center",
    title: "手机号码",
    width: 160,
  },
  {
    dataIndex: "idCard",
    align: "center",
    title: "身份证号",
  },
  // {
  //   dataIndex: "idType",
  //   align: "center",
  //   title: "所属机构",
  //   ellipsis: true,
  // },
  {
    dataIndex: "certificate.name",
    align: "center",
    title: "证书名称",
  },
  {
    dataIndex: "certificate.typeName",
    align: "center",
    title: "证书类型",
  },
  {
    dataIndex: "status",
    align: "center",
    title: "状态",
    width: 120,
    scopedSlots: { customRender: "status" },
  },
  {
    dataIndex: "issueDate",
    align: "center",
    title: "发放时间",
    width: 150,
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 180,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "certificateApply",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        tableHeight: window.innerHeight - 255,
        pagination: {
          //分页器
          showQuickJumper: true,
          hideOnSinglePage: true,
          total: 0,
          current: 1, //页码
          pageSize: 10, //页容
          showTotal: (total) => `共 ${total} 条`,
        },
      },
      where: {
        //查询条件
        keyword: {
          certificateName: "",
          studentName: "",
        },
      },
      selectRows: [], //选中项编号
      isloading: false,
      addedit: {
        //编辑
        visible: false,
        creId: 0,
        userId: 0,
        title: "",
        type: 0,
      },
    };
  },
  components: {},
  mounted: function () {
    that = this;
    if (window.innerWidth > 1440) {
      that.list.pagination.pageSize = 20;
    } else {
      that.list.pagination.pageSize = 10;
    }
    window.onresize = () => {
      that.list.tableHeight = window.innerHeight - 255;
    };
    this.$nextTick(function () {
      this.GetList();
    });
  },
  methods: {
    GetList() {
      //获取列表
      this.isloading = true;
      this.clearRows();
      this.$api.certificate
        .getRecordList(
          this.list.pagination.current,
          this.list.pagination.pageSize,
          this.where.keyword.certificateName,
          this.where.keyword.studentName
        )
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            this.list.datas = res.data.records;
            this.list.pagination.total = Number(res.data.total);
          } else {
            this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    onChange(page) {
      //页面切换回调
      this.list.pagination.current = page.current;
      this.GetList();
    },
    onRowChange(selectedRowKeys) {
      //table 多选
      this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      this.selectRows = [];
    },
    searchList() {
      this.list.pagination.current = 1;
      this.GetList();
    },
    createCert(id, middleId) {
      this.$api.certificate.createCertificate(id, middleId).then((res) => {
        if (res.errorCode == this.$msg.responseCode.success) {
          this.$message.success(res.errorMsg);
          this.GetList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    delCertRecord(id) {
      this.$api.certificate.deleteRecordById(id).then((res) => {
        if (res.errorCode == this.$msg.responseCode.success) {
          this.$message.success(res.errorMsg);
          this.GetList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    showCert(url) {
      window.open(url);
    },
  },
};
</script>
<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
</style>